import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const isMobile = useSelector((state) => state.wallet.isMobile);

  const onToggleLanguageClick = (newLocale) => {
    if (newLocale === 'jp') {
      localStorage.setItem('USERLANGUAGE', 'ja');
    } else if (newLocale === 'cn') {
      localStorage.setItem('USERLANGUAGE', 'zh');
    } else {
      localStorage.setItem('USERLANGUAGE', newLocale);
    }
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };
  const clickJump = (url) => {
    console.log(router);
    window.open(url);
  };

  const switchLanguage = () => {
    const lang = navigator.language;
    let code = lang.substring(0, 2);
    const userlanguage = localStorage.getItem('USERLANGUAGE');
    if (userlanguage && userlanguage !== '') {
      code = userlanguage;
    }
    if (code === 'zh') {
      onToggleLanguageClick('cn');
      return;
    }
    if (code === 'ja') {
      onToggleLanguageClick('jp');
      return;
    }
    onToggleLanguageClick('en');
    return;
  };

  useEffect(() => {
    switchLanguage();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#464646',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '90%' : '86.35%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            color: '#8F8F8F',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '18px',
              height: '18px',
              marginRight: '10px',
            }}
            src="/static/language.png"
            alt="language"
          />
          <Box
            sx={{
              cursor: 'pointer',
              color: locale === 'jp' ? '#FFFFFF' : '#8F8F8F',
            }}
            onClick={() => onToggleLanguageClick('jp')}
          >
            JP
          </Box>
          <Box sx={{ margin: '10px' }}>|</Box>
          <Box
            sx={{
              cursor: 'pointer',
              color: locale === 'en' ? '#FFFFFF' : '#8F8F8F',
            }}
            onClick={() => onToggleLanguageClick('en')}
          >
            EN
          </Box>
          <Box sx={{ margin: '10px' }}>|</Box>
          <Box
            sx={{
              cursor: 'pointer',
              color: locale === 'cn' ? '#FFFFFF' : '#8F8F8F',
            }}
            onClick={() => onToggleLanguageClick('cn')}
          >
            CN
          </Box>
        </Box>
        {isMobile && (
          <Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Box>
        )}
        <Box
          sx={{
            display: 'flex',
            height: '16px',
          }}
        >
          <Box
            component="img"
            sx={{
              height: '100%',
              marginRight: '20px',
              cursor: 'pointer',
            }}
            src="/static/discord.png"
            alt="discord"
            onClick={() => clickJump('https://discord.gg/A3sV3PtUSZ')}
          />
          <Box
            component="img"
            sx={{
              height: '100%',
              marginRight: '20px',
              cursor: 'pointer',
            }}
            src="/static/twitter.png"
            alt="twitter"
            onClick={() => clickJump('https://twitter.com/blockchainlock')}
          />
          <Box
            component="img"
            sx={{
              height: '100%',
              cursor: 'pointer',
            }}
            src="/static/web.png"
            alt="web"
            onClick={() => clickJump('https://keyvox.co')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
