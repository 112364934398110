import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Box from '@mui/material/Box';
import React, { useRef } from 'react';

const Layout = ({ children }) => {
  const ref = useRef(null);
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        minHeight: 'calc(100vh - 83px)',
        // overflow: 'hidden',
        // backgroundColor: 'black',
        paddingBottom: '83px',
      }}
    >
      <Header />
      {/* <Box
        ref={ref}
        sx={{
          width: '100%',
          height: 'calc(100% - 70px)',
          overflow: 'auto',
        }}
      > */}
        {
          // children not Array so React.Children.map is required
          React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return null;
            }
            // add layout ref to child
            const childProps = {
              ...child.props,
              layoutRef: ref,
            };
            return React.cloneElement(child, childProps);
          })
        }
      {/* </Box> */}
      <div className="bg-black absolute bottom-0 w-full">
        <div className="mx-auto w-headWidth flex flex-row justify-start text-[12px] text-[white] pt-[35px] pb-[35px] max-md:w-full">
          <div className="mr-[25px] max-md:ml-[1rem]">© 2024 YUHULABS LIMITED</div>
          <div className="mr-[25px]"><a className="text-white hover:underline" target="_blank" href="https://www.shaolinkungfu.io/shaol/privacy_policy.pdf">隐私条款</a></div>
          <div><a className="text-white hover:underline" target="_blank" href="https://www.shaolinkungfu.io/shaol/Terms_of_Service.pdf">用户协议</a></div>
        </div>
      </div>
    </Box>
  );
};

export default Layout;
