// theme/themeConfig.ts
import { theme as AtTheme } from 'antd';
const { darkAlgorithm, compactAlgorithm } = AtTheme;


const theme = {
	algorithm: darkAlgorithm,
	token: {
		colorPrimary: "white",
		colorTextLightSolid: "#000",
	}
	  
};

export default theme;
