import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { initialDialog } from '@/store/actions/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';

const SimpleDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const { t } = useTranslation('common');
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={() => dispatch(initialDialog())}
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
                background: 'black',
                borderRadius: '20px',
                color: 'white',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {
                width: '600px',
                background: 'black',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: 'white',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
        }
      >
        <Box
          sx={{
            marginTop: '30px',
            marginBottom: '30px',
            fontFamily: 'Gen Shin Gothic P',
            fontSize: isMobile ? '18px' : '30px',
            letterSpacing: '0.6px',
          }}
        >
          {t('mint.dialog.title')}
        </Box>
        <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
        <Box
          sx={{
            width: '65%',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '38px 0 30px',
          }}
        >
          <Box sx={{ marginLeft: '', fontSize: '16px' }}>{type}</Box>
          <Box sx={{ display: 'flex' }}>
            {type === 'MATIC' ? (
              <Box
                component="img"
                src="/static/eth.jpg"
                sx={{
                  width: '46px',
                  height: '46px',
                }}
              />
            ) : (
              <Box
                sx={{
                  width: '46px',
                  height: '46px',
                  borderRadius: 999,
                  background: 'lightgrey',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '35px',
                    height: '35px',
                  }}
                  component="img"
                  src="/static/eth.webp"
                />
              </Box>
            )}
            <Box sx={{ marginLeft: isMobile ? '10px' : '30px' }}>
              {totalPrice}
            </Box>
          </Box>
        </Box>
        {step !== 1 && (
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'start',
              margin: '10px 0',
            }}
          >
            <Box
              sx={{
                width: '46px',
                height: '46px',
                position: 'relative',
                display: 'flex',
                left: '-16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box sx={{ fontSize: '10px', zIndex: '9' }}>1</Box>
              <Box
                sx={{
                  top: 0,
                  position: 'absolute',
                  width: '46px',
                  height: '46px',
                }}
                component="img"
                src={`/static/${active === 1 ? 'load_active.gif' : 'load.png'}`}
              />
            </Box>
            <Box>
              <Box>{t('mint.dialog.1.title')}</Box>
              <Box
                sx={{
                  color: '#4C4948',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {t('mint.dialog.1.description')}
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            justifyContent: 'start',
            margin: '27px 0',
          }}
        >
          <Box
            sx={{
              width: '46px',
              height: '46px',
              position: 'relative',
              display: 'flex',
              left: '-16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ fontSize: '10px', zIndex: '9' }}>{step}</Box>
            <Box
              sx={{
                top: 0,
                position: 'absolute',
                width: '46px',
                height: '46px',
              }}
              component="img"
              src={`/static/${
                step === 1
                  ? active === 1
                    ? 'load_active.gif'
                    : 'load.png'
                  : active === 2
                  ? 'load_active.gif'
                  : 'load.png'
              }`}
            />
          </Box>
          <Box>
            <Box>{t('mint.dialog.2.title')}</Box>
            <Box
              sx={{
                color: '#4C4948',
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('mint.dialog.2.description')}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            justifyContent: 'start',
            margin: '28px 0',
            color: errorText !== '' ? 'red' : '#00AFEC',
          }}
        >
          <Box sx={{ marginLeft: isMobile ? '' : '10%' }}>
            {errorText !== '' ? errorText : t('mint.dialog.wait')}
          </Box>
        </Box>
        {errorText && (
          <Box
            component="button"
            sx={{
              margin: '10px 0 30px',
              width: '50%',
              height: '54px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              background: '#5F5F5F',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '28px',
              color: 'white',
            }}
            variant="contained"
            onClick={() => {
              dispatch(initialDialog());
            }}
          >
            Close
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default SimpleDialog;
