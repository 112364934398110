import { all } from 'redux-saga/effects';

import user from './wallet';

// 合并所有saga
function* rootSaga() {
  yield all([...user]);
}

export default rootSaga;
