import { takeLatest } from 'redux-saga/effects';
import { walletActions } from '../actions/wallet';

function* loadListSaga() {}

const listSaga = [
  takeLatest(walletActions.LIST, loadListSaga), // takeLatest：匹配action，只触发action对应的最后一次reducer执行
];

export default listSaga;
