import { combineReducers } from 'redux';

import wallet from './wallet';

// 合并所有reducer
const rootReducer = combineReducers({
  wallet,
});

export default rootReducer;
