import { Box, Dialog, Skeleton } from '@mui/material';
import { useTranslation } from 'next-i18next';

const LoadingDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const { t } = useTranslation('common');
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box sx={{}}>
        <Skeleton
          sx={{ bgcolor: '#7f7f7f', color: '#fff', fontSize: '18px' }}
          variant="rectangular"
        >
          LOADING...
        </Skeleton>
      </Box>
    </Dialog>
  );
};

export default LoadingDialog;
