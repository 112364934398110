import { HYDRATE } from 'next-redux-wrapper';
import { walletActions } from '../actions/wallet';
import Web3 from 'web3';
import abi from '@/pages/mint/KEYVOXNFT.json';

const initialState = {
  modal: {
    isOpen: false,
    title: '',
    content: '',
    btnText: '',
    jumpUrl: '',
  },
  dialog: {
    isOpen: false,
    step: 1,
    type: 'MATIC',
    totalPrice: 0,
    active: 1,
    errorText: '',
  },
  signdialog: {
    isOpen: false,
    step: 1,
    type: '',
    totalPrice: 0,
    active: 1,
    errorText: '',
  },
  loading: {
    isOpen: false,
    step: 1,
    type: 'MATIC',
    totalPrice: 0,
    active: 1,
    errorText: '',
  },
  quitDialog: {
    isOpen: false,
    step: 1,
    type: 'MATIC',
    totalPrice: 0,
    active: 1,
    errorText: '',
  },
  tedianDialog: {
    isOpen: false,
    step: 1,
    type: 'MATIC',
    totalPrice: 0,
    active: 1,
    errorText: '',
  },
  galleryDialog: {
    isOpen: false,
    step: 1,
    type: 'MATIC',
    totalPrice: 0,
    toNFT:null,
    active: null,
    errorText: '',
  },
  choice: {
    isOpen: false,
    type: 'metamask',
    provider: null,
    baseProvider: null,
  },
  checkNet: {
    isOpen: false,
    jumpUrl: '',
  },
  isMobile: false,
  httpContract: new new Web3(
    new Web3.providers.HttpProvider(process.env.NEXT_PUBLIC_RPCURLS),
  ).eth.Contract(abi.abi, process.env.NEXT_PUBLIC_NFT_ADDRESS),
};

function userReducer(state = initialState, action) {

  switch (action.type) {
    case HYDRATE: {
      // 必需
      return { ...state }; // 注意与单文件reducer和saga的区别
    }
    case walletActions.MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      };
    case walletActions.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case walletActions.QUITDIALOG:
      return {
        ...state,
        quitDialog: {
          ...state.loading,
          ...action.payload,
        },
      };
    case walletActions.TEDIANDIALOG:
      return {
        ...state,
        tedianDialog: {
          ...state.loading,
          ...action.payload,
        },
      };
    case walletActions.DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...action.payload,
        },
      };
    case walletActions.SIGNDIALOG:
      return {
        ...state,
        signdialog: {
          ...state.dialog,
          ...action.payload,
        },
      };
    case walletActions.CHECK_NET:
      return {
        ...state,
        checkNet: {
          ...state.checkNet,
          ...action.payload,
        },
      };
    case walletActions.CHOOSE_WALLET:
      return {
        ...state,
        choice: {
          ...state.choice,
          ...action.payload,
        },
      };
    case walletActions.IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case walletActions.INITIAL_LOADINGDIALOG:
      return {
        ...state,
        loading: {
          isOpen: false,
          step: 1,
          type: 'MATIC',
          totalPrice: 0,
          active: 1,
          errorText: '',
        },
      };
    case walletActions.INITIAL_QUITDIALOG:
      return {
        ...state,
        quitDialog: {
          isOpen: false,
          step: 1,
          type: 'MATIC',
          totalPrice: 0,
          active: 1,
          errorText: '',
        },
      };
    case walletActions.INITIAL_TEDIANDIALOG:
      return {
        ...state,
        tedianDialog: {
          isOpen: false,
          step: 1,
          type: 'MATIC',
          totalPrice: 0,
          active: 1,
          errorText: '',
        },
      };
    case walletActions.INITIAL_DIALOG:
      return {
        ...state,
        dialog: {
          isOpen: false,
          step: 1,
          type: 'MATIC',
          totalPrice: 0,
          active: 1,
          errorText: '',
        },
      };
    case walletActions.INITIAL_MODAL:
      return {
        ...state,
        modal: {
          isOpen: false,
          title: '',
          content: '',
          btnText: '',
          jumpUrl: '',
        },
      };
    case walletActions.INITIAL_CHOOSE_WALLET:
      return {
        ...state,
        choice: {
          isOpen: false,
          type: 'metamask',
          provider: null,
        },
      };
    case walletActions.INITIAL_CHECK_NET:
      return {
        ...state,
        checkNet: {
          isOpen: false,
          type: 'metamask',
          provider: null,
        },
      };
    case walletActions.INITIAL_GALLERYDIALOG:
        return {
          ...state,
          galleryDialog: {
            isOpen: false,
            step: 1,
            type: 'MATIC',
            totalPrice: 0,
            active: null,
            toNFT: null,
            errorText: '',
            ...action.payload,
          },
        };
    default:
      return state;
  }
}

export default userReducer;
