import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Box, MenuList, SvgIcon } from "@mui/material";
import MetaMaskOnboarding from "@metamask/onboarding";
import Fade from "@mui/material/Fade";
import { useTranslation } from "next-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@/components/Modal";
import Dialog from "@/components/Dialog";
import { Menu } from "@mui/material";
import ChooseWallet, {
  GetMetaMaskConnectAddressAndProvider,
  GetWalletConnectAddressAndProvider,
  GetOKXConnectAddressAndProvider,
} from "@/components/ChooseWallet";
import useDispatchAction from "@/hooks/useDispatchAction";
import {
  handleChooseWallet,
  handleIsMobile,
  handleQuitDialog,
} from "@/store/actions/wallet";
import CheckNet from "@/components/CheckNet";
import LoadingDialog from "@/components/LoadingDialog";
import Web3 from "web3";
import QuitDialog from "@/components/QuitDialog";
import TedianDialog from "@/components/TedianDialog";
import SignDialog from "@/components/SignDialog";
import { Button as AtButton, Drawer, message } from "antd";
import {
  WalletOutlined,
} from "@ant-design/icons";
import { MenuOutlined } from "@mui/icons-material";

const Header = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const onboarding = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuShow, setMenuShow] = useState(false);
  const open = Boolean(anchorEl);
  const modal = useSelector((state) => state.wallet.modal);
  const dialog = useSelector((state) => state.wallet.dialog);
  const signdialog = useSelector((state) => state.wallet.signdialog);
  const loadingDialog = useSelector((state) => state.wallet.loading);
  const quitDialog = useSelector((state) => state.wallet.quitDialog);
  const tedianDialog = useSelector((state) => state.wallet.tedianDialog);

  const choice = useSelector((state) => state.wallet.choice);
  const checkNet = useSelector((state) => state.wallet.checkNet);
  const { t } = useTranslation("common");

  const isMobile = useSelector((state) => state.wallet.isMobile);

  const reloadAddressAndProvider = async (walletType) => {
    if (walletType === "metamask") {
      const { metaMaskProvider, address } =
        await GetMetaMaskConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          type: "metamask",
          baseProvider: metaMaskProvider,
          provider: new Web3(metaMaskProvider),
          address: address,
        }),
      );
    }
    if (walletType === "walletConnect") {
      const { walletConnectProvider, address } =
        await GetWalletConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          baseProvider: walletConnectProvider,
          type: "walletConnect",
          provider: new Web3(walletConnectProvider),
          address,
        }),
      );
    }
    if (walletType === "okx") {
      const { okxProvider, address } =
        await GetOKXConnectAddressAndProvider();
      dispatch(
        handleChooseWallet({
          isOpen: false,
          baseProvider: okxProvider,
          type: "okx",
          provider: new Web3(okxProvider),
          address,
        }),
      );
    }
  };
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
      const walletType = sessionStorage.getItem("walletType");
      reloadAddressAndProvider(walletType);
    }
    dispatch(
      handleIsMobile(
        navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/iPhone/i),
      ),
    );
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const MenuItem = ({ children, onClick }) => {
    return (
      <Box
        onClick={onClick}
        sx={{
          height: "100%",
          padding: "0 40px 0 0",
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <>
    <Modal
        open={modal.isOpen}
        title={modal.title}
        content={modal.content}
        btnText={modal.btnText}
        jumpUrl={modal.jumpUrl}
      />
      <Dialog
        open={dialog.isOpen}
        totalPrice={dialog.totalPrice}
        type={dialog.type}
        step={dialog.step}
        active={dialog.active}
        errorText={dialog.errorText}
      />
      <SignDialog
        open={signdialog.isOpen}
        totalPrice={signdialog.totalPrice}
        type={signdialog.type}
        step={signdialog.step}
        active={signdialog.active}
        errorText={signdialog.errorText}
      />
      <LoadingDialog
        open={loadingDialog.isOpen}
        totalPrice={loadingDialog.totalPrice}
        type={loadingDialog.type}
        step={loadingDialog.step}
        active={loadingDialog.active}
        errorText={loadingDialog.errorText}
      />
      <QuitDialog
        open={quitDialog.isOpen}
        totalPrice={quitDialog.totalPrice}
        type={quitDialog.type}
        step={quitDialog.step}
        active={quitDialog.active}
        errorText={quitDialog.errorText}
      />
      <TedianDialog
        open={tedianDialog.isOpen}
        totalPrice={tedianDialog.totalPrice}
        type={tedianDialog.type}
        step={tedianDialog.step}
        active={tedianDialog.active}
        errorText={tedianDialog.errorText}
      />
      <ChooseWallet open={choice.isOpen} />
      <CheckNet open={checkNet.isOpen} jumpUrl={checkNet.jumpUrl} />
      <div className="h-[84px] bg-black max-md:hidden">
        <div style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: 20,
        }} className="w-full h-[84px] bg-black/[.2] border-b-1">
          <div className="text-white w-headWidth h-[84px] items-center mx-auto flex flex-row justify-between">
            <div className="flex flex-row justify-items-start items-center">
              <img onClick={() => {
                router.push("/");
              }} className="cursor-pointer w-[41px]" src="/static/ShaoLinlogov2.png" alt="" />
              <div className="text-[16px] w-[482px] ml-[88px] text-shaolinMainColor">
                <ul className="flex flex-row justify-start list-none">
                  <li className="cursor-pointer mr-[48px]" onClick={() => {
                    router.push("/gallery");
                  }}>画廊</li>
                  <li className="cursor-pointer mr-[48px]" onClick={() => {
                    router.push("/collection");
                  }}>收藏</li>
                  <li className="hidden" onClick={() => {
                    message.info("待开放")
                  }}>文档</li>
                  <li className="hidden">English-en</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-row justify-start">
              <ul className="w-[138px] text-black flex flex-row justify-between list-none items-center">
                <li className="cursor-pointer" onClick={() => { 
                  window.open("https://twitter.com/KUNGFU_BODY", "_blank")
                }}>
                  <img src="/static/twitter.svg" alt="twitter" />
                  </li>
                  {/* w-[168px] */}
                {/* <li className="cursor-pointer" onClick={() => { 
                  window.open("https://discord.gg/zGdT77C988", "_blank")
                }}>
                  <img src="/static/discord.svg" alt="discord" />
                </li> */}
                <li className="cursor-pointer" onClick={() => { 
                  window.open("https://opensea.io/collection/kungfubody", "_blank")
                }}>
                  <img src="/static/opensea.svg" alt="opensea" />
                </li>
                <li className="cursor-pointer" onClick={() => { 
                  window.open("https://www.okx.com/cn/web3/marketplace/nft/collection/bsc/kungfubody", "_blank")
                }}>
                  <img src="/static/okx.svg" alt="okx" />
                </li>
              </ul>
              <AtButton
                className="!bg-black !text-shaolinMainColor !border-none ml-[32px] !rounded-[50px] active:opacity-50"
                type="default"
                size="large"
                icon={<WalletOutlined className="text-[20px]" />}
                onClick={() => {
                  if (choice.address) {
                    dispatch(handleQuitDialog({ isOpen: true }));
                  } else {
                    dispatch(handleChooseWallet({ isOpen: true }));
                  }
                }}
              >{choice.address
                ? `${choice.address.slice(0, 4)}...${choice.address.slice(-4)}`
                : t("header.connect")}</AtButton>
            </div>
          </div>
        </div>
    </div>
    <div className="h-[2.75rem] bg-black md:hidden">
      <div style={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 20,
          }} className="w-full h-[2.75rem] bg-black border-b-1">
        <div className="text-white h-[2.75rem] items-center mx-[1rem] flex flex-row justify-between">
          <div className="flex flex-row justify-items-start items-center">
            <img onClick={() => {
              router.push("/");
            }} className="cursor-pointer w-[1.4rem]" src="/static/ShaoLinlogov2.png" alt="" />
          </div>
          <div className="flex flex-row justify-start">
              {/* <ul className="text-black flex flex-row justify-between list-none items-center">
                <li className="cursor-pointer" onClick={() => { 
                  window.open("https://twitter.com/kungfuloong_nft", "_blank")
                }}>
                  <img src="/static/twitter.svg" alt="twitter" />
                  </li>
              </ul> */}
              <AtButton
                className="!bg-white !text-black !border-none !rounded-[2px] active:opacity-50 !px-[0.5rem] !py-[0.5rem] !h-[2rem] !text-[0.75rem]"
                type="default"
                size="large"
                onClick={() => {
                  if (choice.address) {
                    dispatch(handleQuitDialog({ isOpen: true }));
                  } else {
                    dispatch(handleChooseWallet({ isOpen: true }));
                  }
                }}
              >{choice.address
                ? `${choice.address.slice(0, 4)}...${choice.address.slice(-4)}`
                : t("header.connect")}</AtButton>
                <AtButton
                  className="!bg-black !text-white !border-none !rounded-[2px] active:opacity-50 !px-0 !ml-[0.75rem] !h-[2rem]"
                  type="default"
                  size="large"
                  onClick={() => {
                    setMenuShow(true)
                  }}
                >
                <MenuOutlined />
              </AtButton>
              <Drawer
                width={240}
                onClose={() => { setMenuShow(false) }}
                open={menuShow}>
                  <div className="text-[1rem] mt-[1rem] text-shaolinMainColor">
                    <ul className="list-none">
                      <li className="cursor-pointer mt-4 ml-8 " onClick={() => {
                        router.push("/gallery");
                      }}>画廊</li>
                      <li className="cursor-pointer mt-4 ml-8" onClick={() => {
                        router.push("/collection");
                      }}>收藏</li>
                      <li className="hidden" onClick={() => {
                        message.info("待开放")
                      }}>文档</li>
                      <li className="hidden">English-en</li>
                    </ul>
                  </div>
              </Drawer>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
