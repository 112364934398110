import { Box, Button, Dialog, OutlinedInput, Skeleton } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { initialTedianDialog } from '@/store/actions/wallet';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

const TedianDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const choice = useSelector((state) => state.wallet.choice);
  const [signMsg, setSignMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { t } = useTranslation('common');
  const doSinglePer = async ({ name, zip, address1, address2, phone }) => {
    setErrorMsg('');
    setSignMsg(true);
    // 先显示TODO 的时候显示  承认中的状态
    const jstr = {
      name,
      zip,
      address1,
      address2,
      phone,
      tokenid: step,
    };
    //  承認中・・・
    let fromAddress = await choice.provider.eth.getAccounts();
    // 如果成功拿到 签名地址封装成
    let signature = '';
    try {
      signature = await choice.provider.eth.personal.sign(
        JSON.stringify(jstr),
        fromAddress[0],
      );
    } catch (e) {
      console.log(JSON.stringify(jstr));
      setErrorMsg('User denied transaction signature.');
      setSignMsg(false);
      return false;
    }

    const postdata = {
      address: fromAddress[0],
      msg: JSON.stringify(jstr),
      sig: signature,
      version: '2',
    };
    const apiUrl = process.env.NEXT_PUBLIC_ENV === 'production' ? '/prodapi' : '/api';
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        'x-api-key': '26yfyxDQvn5l0rC7lnqlg1gp1MlLl6mB2PKAXlBs',
      }),
      body: JSON.stringify(postdata),
    });
    response.json().then((res) => {
      setSignMsg(false);
      if (res && res.code === 200) {
        initialTedianDialog();
      } else {
        // if (res && res.msg) {
        //   setErrorMsg(res && res.msg);
        // }
        setErrorMsg(res && res.msg);
      }
    });
  };

  const onSubmit = ({ name, postCode, address, street, phone }) => {
    console.log(name);
    const formData = {
      name,
      zip: postCode,
      address1: address,
      address2: street,
      phone,
    };
    doSinglePer({ ...formData });
  };

  const InputField = ({ sx, register, error, label }) => (
    <Box
      sx={{
        width: '100%',
        marginTop: '17px',
        fontSize: '18px',
        color: '#00AFEC',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box>{label}</Box>
      <Box sx={{ ...sx }}>
        <Box
          component="input"
          {...register}
          error={errors.name}
          sx={{
            marginTop: '5px',
            width: 'calc(100% - 18px)',
            borderRadius: '10px',
            fontSize: '18px',
            color: '#4C4948',
            padding: '10px',
            background: error ? 'rgba(255, 67, 55, 0.2)' : '#F2F7FA',
            border: error ? '2px solid #FF4337' : '2px solid #F2F7FA',
            '&:focus-visible': {
              outline: 'none',
            },
          }}
          fullWidth
          id=""
        />
      </Box>
      {error && (
        <Box
          sx={{
            marginTop: '10px',
            fontWeight: 700,
            fontSize: '9px',
            lineHeight: '130%',
            color: '#FF4337',
          }}
        >
          {error?.message}
        </Box>
      )}
    </Box>
  );

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{
        '.MuiDialog-paper': { background: 'transparent' },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={
          isMobile
            ? {
                width: '100%',
                background: 'white',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                display: 'flex',
                borderRadius: '20px',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {
                width: '600px',
                background: 'white',
                color: '#4C4948',
                borderRadius: '20px',
                fontSize: '16rem',
                fontFamily: 'Helvetica Neue',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
              marginRight: '15px',
              fontSize: '20px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setErrorMsg('');
              setSignMsg(false);
              dispatch(initialTedianDialog());
            }}
          >
            x
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            fontFamily: 'Gen Shin Gothic P',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0rem 20px 10px 10px',
            marginTop: '37px',
            width: isMobile ? '70%' : '470px',
          }}
        >
          <Box sx={{ fontSize: '22px', textAlign: 'center' }}>
            {t('redeem.dtitle')}
          </Box>
          <Box
            sx={{
              width: '54px',
              backgroundColor: 'black',
              height: '4px',
              marginTop: '27px',
            }}
          ></Box>
          <Box
            sx={
              isMobile
                ? {
                    padding: '20px 0rem 0 0rem',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }
                : {
                    padding: '20px 0rem 0 0rem',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '414px',
                  }
            }
          >
            <Box>{t('redeem.dcontent')}</Box>
            <InputField
              sx={{ width: '100%' }}
              label={t('redeem.name')}
              register={register('name', {
                required: 'name is required',
              })}
              error={errors.name}
            />
            <InputField
              sx={{ width: '40%' }}
              label={t('redeem.zip')}
              register={register('postCode', {
                required: 'postCode is required',
              })}
              error={errors.postCode}
            />
            <InputField
              sx={{ width: '100%' }}
              label={t('redeem.address1')}
              register={register('address', {
                required: 'address is required',
              })}
              error={errors.address}
            />
            <InputField
              sx={{ width: '100%' }}
              label={t('redeem.address2')}
              register={register('street', {
                required: 'street is required',
              })}
              error={errors.street}
            />
            <InputField
              sx={{ width: '100%' }}
              label={t('redeem.phone')}
              register={register('phone', {
                required: 'phone is required',
              })}
              error={errors.phone}
            />
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Box
                component="button"
                type="submit"
                sx={{
                  marginTop: '54px',
                  background: '#5F5F5F',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  padding: '10px',
                  width: isMobile ? '100%' : '320px',
                  height: '54px',
                  cursor: 'pointer',
                  border: '1px solid #5F5F5F',
                  borderRadius: '28px',
                  color: 'white',
                }}
              >
                {t('redeem.sign')}
              </Box>
            </Box>
            {signMsg ? (
              <Box
                sx={{
                  width: '100%',
                  marginTop: '20px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#00bcec',
                  marginBottom: '30px',
                }}
              >
                {signMsg ? t('redeem.status') : ''}
              </Box>
            ) : (
              ''
            )}
            {errorMsg ? (
              <Box
                sx={{
                  width: '100%',
                  marginTop: '20px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'red',
                  marginBottom: '30px',
                }}
              >
                {errorMsg}
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TedianDialog;
