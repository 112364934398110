import '@/styles/globals.scss';
import "@/styles/scrollbar.scss";
import { wrapper } from '@/store';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import Layout from '@/components/Layout';
import { ConfigProvider } from 'antd';
import theme from '@/config/theme';

function MyApp({ Component, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => <ConfigProvider theme={theme}>
    <Layout>{page}</Layout>
  </ConfigProvider>)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />
        <meta name="description" content="KungFu BODY" />
        <meta charSet="utf-8" />
        <title>KungFu BODY</title>
        <link rel="shortcut icon" href="/static/ShaoLinlogov2.png" type="image/png" />
      </Head>
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp));
