import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { handleSignDialog, initialDialog } from '@/store/actions/wallet';
import { useDispatch, useSelector } from 'react-redux';

const SignDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const { t } = useTranslation('common');
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={() => dispatch(initialDialog())}
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
                background: 'white',
                borderRadius: '20px',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            : {
                width: '600px',
                background: 'white',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
        }
      >
        <Box
          sx={{
            margin: '30px 0 16px 0',
            fontFamily: 'Gen Shin Gothic P',
            fontSize: isMobile ? '18px' : '30px',
            letterSpacing: '0.6px',
          }}
        >
          {t('besign.title')}
        </Box>
        <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            justifyContent: 'start',
            margin: '10px 0',
          }}
        >
          <Box
            sx={{
              width: '46px',
              height: '46px',
              position: 'relative',
              display: 'flex',
              left: '-16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ fontSize: '10px', zIndex: '9' }}>{step}</Box>
            <Box
              sx={{
                top: 0,
                position: 'absolute',
                width: '46px',
                height: '46px',
              }}
              component="img"
              src={`/static/${
                step === 1
                  ? active === 1
                    ? 'load_active.gif'
                    : 'load.png'
                  : active === 2
                  ? 'load_active.gif'
                  : 'load.png'
              }`}
            />
          </Box>
          <Box>
            <Box>{t('besign.title1')}</Box>
            <Box
              sx={{
                color: '#4C4948',
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('besign.title2')}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            justifyContent: 'start',
            margin: '10px 0 20px',
            color: errorText !== '' ? 'red' : '#00AFEC',
          }}
        >
          <Box sx={{ marginLeft: '10%' }}>
            {errorText !== '' ? errorText : t('mint.dialog.wait')}
          </Box>
        </Box>
        {errorText && (
          <Box
            component="button"
            sx={{
              margin: '10px 0',
              width: '150px',
              height: '48px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              background: '#5F5F5F',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '28px',
              color: 'white',
            }}
            variant="contained"
            onClick={() => {
              dispatch(handleSignDialog({ isOpen: false }));
            }}
          >
            Close
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default SignDialog;
