import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { handleCheckNet } from '@/store/actions/wallet';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

const switchNet = async (choice) => {
  try {
    const feedback = await choice.provider.currentProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: choice.provider.utils.numberToHex(
            process.env.NEXT_PUBLIC_CHAIN_ID,
          ),
        },
      ],
    });
  } catch (switchError) {
    if (
      (switchError.code && switchError.code === 4902) ||
      switchError + '1'.indexOf('wallet_addEthereumChain first')
    ) {
      const feedback = await choice.provider.currentProvider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: choice.provider.utils.numberToHex(
              process.env.NEXT_PUBLIC_CHAIN_ID,
            ),
            chainName: process.env.NEXT_PUBLIC_CHAIN_NAME,
            rpcUrls: [process.env.NEXT_PUBLIC_RPCURLS],
            blockExplorerUrls: [process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URLS],
            nativeCurrency: {
              name: process.env.NEXT_PUBLIC_NATIVE_CURRENCY,
              symbol: process.env.NEXT_PUBLIC_NATIVE_CURRENCY_SYMBOL,
              decimals: parseInt(
                process.env.NEXT_PUBLIC_NATIVE_CURRENCY_DECIMALS,
              ),
            },
          },
        ],
      });
    }
  }
};
export const checkNetwork = async (choice) => {
  let nowchainid = await choice.provider.eth.getChainId();
  console.log('nowchainid', nowchainid);
  console.log(
    'process.env.NEXT_PUBLIC_CHAIN_ID',
    process.env.NEXT_PUBLIC_CHAIN_ID,
  );
  if (Number(nowchainid) !== Number(process.env.NEXT_PUBLIC_CHAIN_ID)) {
    switchNet(choice);
    return false;
  }
  return true;
};

const CheckNet = ({ open, jumpUrl }) => {
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const { t } = useTranslation('common');
  const router = useRouter();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{ '.MuiDialog-paper': { background: 'transparent' } }}
    >
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
                background: 'white',
                borderRadius: '20px',
                color: '#4C4948',
                fontSize: '16px',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
            : {
                width: '60rem',
                background: 'white',
                borderRadius: '20px',
                border: '1px solid #707070',
                color: '#4C4948',
                fontSize: '1.6rem',
                fontFamily: 'Helvetica Neue',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
        }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              margin: '30px 0 16px 0',
              fontFamily: 'Gen Shin Gothic P',
              fontSize: isMobile ? '18px' : '30px',
              letterSpacing: '0.6px',
            }}
          >
            {isMobile ? (
              <Box
                sx={{
                  width: '60%',
                  marginLeft: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                }}
              >
                {t('switchNet.title')}
              </Box>
            ) : (
              <Box>{t('switchNet.title')}</Box>
            )}
          </Box>
          <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0',
            }}
          ></Box>
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'start',
              margin: '10px 0',
            }}
          >
            <Box
              sx={{
                color: '#4C4948',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {t('switchNet.content')}
            </Box>
          </Box>
        </Box>
        <Box
          component="button"
          sx={{
            margin: '50px 0',
            width: '50%',
            height: '54px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            background: '#5F5F5F',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '28px',
            color: 'white',
          }}
          variant="contained"
          onClick={() => {
            dispatch(
              handleCheckNet({
                isOpen: false,
              }),
            );
            if (jumpUrl) {
              router.push(jumpUrl);
            }
          }}
        >
          {t('switchNet.buttonText')}
        </Box>
      </Box>
    </Dialog>
  );
};

export default CheckNet;
