import { Box, Button, Dialog, Skeleton } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { handleChooseWallet, initialQuitDialog } from '@/store/actions/wallet';
import Web3 from 'web3';
import Header from '@/components/Header';

const QuitDialog = ({ open, step, type, totalPrice, active, errorText }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const choice = useSelector((state) => state.wallet.choice);
  const { t } = useTranslation('common');

  const walletClose = () => {
    const walletType = sessionStorage.getItem('walletType');
    sessionStorage.clear();
    console.log(choice.baseProvider);
    if (choice.baseProvider && (walletType === 'walletConnect' || walletType === 'okx')) {
      choice.baseProvider.disconnect();
    }
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-describedby="mint-dialog-description"
      sx={{
        '.MuiDialog-paper': { background: 'transparent' },
      }}
    >
      <div
      className="bg-black flex bold flex-col items-center md:w-[500px] max-md:w-[300px] text-white text-[16px] p-[30px]"

        // sx={
        //   isMobile
        //     ? {
        //         width: '100%',
        //         minWidth: '300px',
        //         background: 'black',
        //         color: 'white',
        //         fontSize: '16px',
        //         fontFamily: 'Helvetica Neue',
        //         fontWeight: 'bold',
        //         borderRadius: '20px',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         alignItems: 'center',
        //       }
        //     : {
        //         width: '400px',
        //         background: 'black',
        //         borderRadius: '20px',
        //         border: '1px solid #707070',
        //         color: 'white',
        //         fontSize: '16px',
        //         fontFamily: 'Helvetica Neue',
        //         fontWeight: 'bold',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         alignItems: 'center',
        //       }
        // }
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              margin: '30px 0 16px 0',
              fontFamily: 'Gen Shin Gothic P',
              fontSize: '24px',
              letterSpacing: '0.6px',
            }}
          >
            {t('quitDialog.title')}
          </Box>
          <Box sx={{ height: '4px', width: '54px', background: 'black' }} />
        </Box>
        <Box
          sx={{
            marginTop: '40px',
            display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              flex:1,
              marginRight: '10px',
              // padding: '10px 20px 10px 20px',
              borderRadius: '20px',
              // marginRight: '40px',
              height: '48px',
              color: 'white',
              borderColor: 'white',
              ":hover": {
                borderColor: 'white',
                opacity: 0.7,
              }
            }}
            onClick={() => {
              dispatch(
                initialQuitDialog({
                  isOpen: false,
                }),
              );
            }}
          >
            {t('quitDialog.cancel')}
          </Button>
          <Button
            variant="contained"
            sx={{
              flex:1,
              marginLeft: '10px',
              // padding: '10px 20px  10px 20px',
              borderRadius: '20px',
              height: '48px',
              // marginTop: '30px',
              // marginBottom: '30px',
              background: 'white',
              color: 'black',
              ":hover": {
                background: 'white',
                opacity: 0.7,
              }
            }}
            onClick={() => {
              walletClose();
            }}
          >
            {t('quitDialog.quit')}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default QuitDialog;
